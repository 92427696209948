export const accountSignatures = {
  channel: 'accountchannel',
  chaincode: 'accountCC',
  action: {
    addWallet: 'addWallet',
    addAccount: 'addAccount',
    activateWallet: 'activateWallet',
    blockWallet: 'blockWallet',
    activateAccount: 'activateAccount',
    blockAccount: 'blockAccount',
    verifyUserAccount: 'verifyUserAccount',
    checkAndLoadAccount: 'checkAndLoadAccount',
    getAllUserWallets: 'getAllUserWallets',
    getAccountInfoByAccountID: 'getAccountInfoByAccountID',
    getAccountByCurrencyType: 'getAccountByCurrencyType'
  }
}
export const offerSignatures = {
  channel: 'offerchannel',
  chaincode: 'offerCC',
  action: {
    addOffer: 'addOffer',
    getUserIncomingRequestsByStatus: 'getUserIncomingRequestsByStatus',
    getUserOutgoingRequestsByStatus: 'getUserOutgoingRequestsByStatus',
    getHistoryUserIncomingRequests: 'getHistoryUserIncomingRequests',
    getHistoryUserOutgoingRequests: 'getHistoryUserOutgoingRequests',
    getOffer: 'getOffer',
    changeOfferStatusByOperationId: 'changeOfferStatusByOperationId',
    getRequestsByStatus: 'getRequestsByStatus',
    isOfferApproved: 'isOfferApproved',
    setOfferStatusByOperationId: 'setOfferStatusByOperationId'
  }
}
export const referralSignatures = {
  channel: 'promocodechannel',
  chaincode: 'promocodeCC',
  action: {
    addReferralProgram: 'addReferralProgram'
  }
}
export const offerStatusSignatures = {
  channel: 'offerstatuschannel',
  chaincode: 'offerstatusCC',
  action: {
    addKeyValue: 'addKeyValue',
    addKeyValueArray: 'addKeyValueArray'
  }
}
export const OfferStatusSignatures = {
  channel: 'offerstatuschannel',
  chaincode: 'offerstatusCC',
  action: {
    addKeyValue: 'addKeyValue',
    addKeyValueArray: 'addKeyValueArray'
  }
}
export const TransactionSignatures = {
  channel: 'transactionchannel',
  chaincode: 'transactionCC',
  action: {
    lockFundsOnAccount: 'lockFundsOnAccount',
    accountCurrencyBalance: 'accountCurrencyBalance',
    transferFunds: 'transferFunds',
    getUsersTransaction: 'getUsersTransaction',
    loadCryptocurrency: 'loadCryptocurrency',
    lockOutFundsOnAccount: 'lockOutFundsOnAccount',
    lockTransferToMarket: 'lockTransferToMarket',
    unlockFundsOnAccount: 'unlockFundsOnAccount',
    checkExistsTransactionByList: 'checkExistsTransactionByList',
    findLockAmountByOperationIDAndUserId: 'findLockAmountByOperationIDAndUserId',
    loadMarketFounds: 'loadMarketFounds'
  }
}
export const EthereumCodeCCSignatures = {
  channel: 'ethereumcodechannel',
  chaincode: 'ethereumcodeCC',
  action: {
    addCode: 'addCode',
    addKeyValue: 'addKeyValue',
    addKeyValueArray: 'addKeyValueArray',
    getKeyValue: 'getKeyValue',
    getKeyValueArray: 'getKeyValueArray',
    getKeyValueArrayByKeysWithFilter: 'getKeyValueArrayByKeysWithFilter'
  }
}
export const WithdrawalOfferCCSignatures = {
  channel: 'withdrawalofferchannel',
  chaincode: 'withdrawalofferCC',
  action: {
    addOffer: 'addOffer',
    getOffers: 'getOffers',
    getOfferRecords: 'getOfferRecords'
  }
}
export const KycCCSignatures = {
  channel: 'kycchannel',
  chaincode: 'kycCC',
  action: {
    acceptIndividualFormStep: 'acceptIndividualFormStep'
  }
}
export const KycFileCCSignatures = {
  channel: 'filechannel',
  chaincode: 'fileCC',
  action: {
    addFileInfo: 'addFileInfo'
  }
}
export const AutoexchangeSignatures = {
  channel: 'autoexchangechannel',
  chaincode: 'autoexchangeCC',
  action: {
    addAutoexchange: 'addAutoexchange',
    cancelAutoexchange: 'cancelAutoexchange',
    addP2POffer: 'addP2pOffer',
    acceptP2PMatch: 'acceptP2pMatch',
    rejectP2PMatch: 'rejectP2pMatch'
  }
}
export const AdminSignatures = {
  autoExchange: {
    channel: 'systempreferencechannel',
    chaincode: 'systempreferenceCC',
    action: {
      addExchangeRateConstraint: 'addExchangeRateConstraint',
      saveP2pCurrencyParam: 'saveP2pCurrencyParam'
    }
  },
  autoExchangeCC: {
    channel: 'autoexchangechannel',
    chaincode: 'autoexchangeCC',
    action: {
      cancelAllAutoexchanges: 'cancelAllAutoexchanges',
      suspendMatching: 'suspendMatching'
    },
  },
  user: {
    channel: 'userchannel',
    chaincode: 'userCC',
    action: {
      addSystemUser: 'addSystemUser',
      setKycStatus: 'setKycStatus',
      setStatus: 'setStatus'
    }
  },
  commission: {
    channel: 'commissionchannel',
    chaincode: 'commissionCC',
    action: {
      saveGasCommission: 'saveGasCommission',
      saveCommission: 'saveCommission',
      saveLionExchangeRate: 'saveLionExchangeRate',
      saveCryptoenterCommission: 'saveCryptoenterCommission',
      saveAeRequestCommission: 'saveAeRequestCommission'
    }
  },
  currencyConstraints: {
    channel: 'processtypecurrencychannel',
    chaincode: 'processtypecurrencyCC',
    action: {
      savePreference: 'savePreference'
    }
  },
  organization: {
    channel: 'organizationchannel',
    chaincode: 'organizationCC',
    action: {
      addOrganization: 'addOrganization'
    }
  },
  offer: {
    channel: 'offerchannel',
    chaincode: 'offerCC',
    action: {
      addOffer: 'addOffer'
    }
  },
  transaction: {
    channel: 'transactionchannel',
    chaincode: 'transactionCC',
    action: {
      depositFiatFunds: 'depositFiatFunds',
      lockFundsOnAccount: 'lockFundsOnAccount',
    }
  },
  processTypeCurrencyLimits: {
    channel: 'processtypecurrencychannel',
    chaincode: 'processtypecurrencyCC',
    action: {
      savePreference: 'savePreference'
    }
  }
}

const {
  autoExchange,
  autoExchangeCC,
  user,
  commission,
  currencyConstraints,
  organization,
  offer,
  transaction,
  processTypeCurrencyLimits
} = AdminSignatures

export const AdminSignatureDataByName = {
  cryptoenterCommission: [commission.channel, commission.chaincode, commission.action.saveCryptoenterCommission],
  lionCourse: [commission.channel, commission.chaincode, commission.action.saveLionExchangeRate],
  createOrganization: [organization.channel, organization.chaincode, organization.action.addOrganization],
  organizationCommission: [],
  processTypeCurrencyLimits: [processTypeCurrencyLimits.channel, processTypeCurrencyLimits.chaincode, processTypeCurrencyLimits.action.savePreference],
  gasEthCommission: [commission.channel, commission.chaincode, commission.action.saveGasCommission],
  blockingUsers: [user.channel, user.chaincode, user.action.setStatus],
  autoExchangePairsSettings: [autoExchange.channel, autoExchange.chaincode, autoExchange.action.addExchangeRateConstraint],
  p2pSettings: [autoExchange.channel, autoExchange.chaincode, autoExchange.action.saveP2pCurrencyParam],
  autoExchangeControl: [autoExchangeCC.channel, autoExchangeCC.chaincode, autoExchangeCC.action.suspendMatching],
  autoExchangeCancelOffers: [autoExchangeCC.channel, autoExchangeCC.chaincode, autoExchangeCC.action.cancelAllAutoexchanges],
  autoExchangeLionCommission: [commission.channel, commission.chaincode, commission.action.saveAeRequestCommission],
  autoExchangeRateSteps: [],
  autoExchangeCommissionReserve: [],
  systemUserCreate: [user.channel, user.chaincode, user.action.addSystemUser],
  userKycStatus: [user.channel, user.chaincode, user.action.setKycStatus],
  depositFiat: [transaction.channel, transaction.chaincode, transaction.action.depositFiatFunds],
  transferFiatToCashier: [transaction.channel, transaction.chaincode, transaction.action.lockFundsOnAccount],
}
